import React from 'react';
import './imgHover.scss';
import { motion } from 'framer-motion';

const ImgHover = ({item}) =>
{
    return (
        <motion.div initial={{ scale: 0.3 }} viewport={{once:true}} whileInView={{ scale:1}} transition={{duration:1}}>
            <figure className="effect-zoe">
                <img src={item.img} alt={item.title} onClick={() => window.open(item.link ? item.link : item.code)}/>
                <figcaption >
                <p className="description">{item.title}</p>
                    <h2 className='font-bold'>{item.title.split(' ').length > 3 ? item.title.split(' ').slice(0, 3).join(' ') + "...": item.title}</h2>
                    <div className="flex flex-row items-center justify-center gap-4">
                        {item.code && (
                            <a href={item.code} target="_blank" rel="noreferrer"><i className="fa-brands fa-github text-gray-800 text-2xl"></i></a>
                        )}
                        {item.link && (
                            <a href={item.link} target="_blank" rel="noreferrer"><i className="fa-solid fa-arrow-up-right-from-square text-2xl "></i></a>
                        )}
                    </div>
                    
                </figcaption>			
            </figure>
        </motion.div>
     );
}
 
export default ImgHover;
