import React, {useContext, useState} from 'react';
import sun from "assets/Images/icon-sun.svg";
import moon from "assets/Images/icon-moon.svg";
import themeContext, {DARK_MODE} from 'context';
import Typewriter from 'typewriter-effect';
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';

import './slidestyle.scss';


const Slides = ({darkMode}) =>
{
  const [text, setText] = useState("Download CV");
  const themeState = useContext(themeContext);
  const draws = [
    "https://assets6.lottiefiles.com/private_files/lf30_obidsi0t.json",
    "https://assets7.lottiefiles.com/private_files/lf30_gcroxmlt.json",
  ]
  const currentDraw = Math.floor(Math.random() * draws.length);
  const sendEmail = () => {
    setText("Cv Downloaded")
    emailjs.send('markos', 'markos', {message:"Hello Markos! I just downloaded your cv" , name:"New Visitor", subject:"Downloaded Your Cv"}, 'user_xWxaqPvE4K7xNeqiRB7LO')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  
  return (
    <div className='slideShow_main_container relative px-10' id={darkMode ? "dark" : "light"}>
      <img
        src={themeState.state.darkMode ? sun : moon}
        alt="darkModeIcon"
        style={{filter:`${themeState.state.darkMode ? "none" : "invert()" }`}}
        className="w-[30px] h-[30px] cursor-pointer absolute top-10 right-10"
        onClick={() => themeState.dispatch({ type: DARK_MODE })}
      />
      <motion.section
        className='sectionOne'
        initial={{ translateY: 100, opacity:0 }}
        whileInView={{  translateY: 0 , opacity:1}}
        viewport={{ once: true }}
        transition={{ duration: 1 }}>
          <Typewriter
            
                onInit={(typewriter) => {
                typewriter.typeString(`Hi!<br/>I'm Markos <small className='text-xs'>👋</small>`)
                .pauseFor(3000)
                .changeDeleteSpeed(1000)
                .deleteAll()
                .typeString(`I'm<br/> a Front End Developer~!`)
                .pauseFor(3000)
                
                .start();
              
              
            }}
            options={{loop:true, cursorClassName:"typeWriter_Cursor", delay: 75, wrapperClassName:"typeWriter"}}
              />
            <p>100% Self Eduacated Programer have two years of experience.</p>
              <a
                href="/Markos Latest CV.pdf"
                download={true}
                onClick={sendEmail}
              >{text}<i className="fa-solid fa-download"></i>
              </a>
        </motion.section>
        <motion.div
          className='img_container'
          initial={{ translateX: 200 }}
          whileInView={{  translateX: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}>
            <lottie-player src={draws[currentDraw]} background="transparent" speed="1" className='Markos1' loop={true} autoplay="true"/>
        </motion.div>
    </div>
    );
}
 
export default Slides;
