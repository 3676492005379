import React, { useState, useEffect, useContext } from 'react';
import './sidenaveresponsive.scss';
import SocialMediaLinks from 'Components/shared/socialMedia-links';
import NavLinks from 'Components/shared/navLinks';
import Me from 'assets/Images/n.png';
import Me2 from 'assets/Images/m.png';
import themeContext, { SHOW_NAVBAR } from 'context';

const SideNav = ({darkMode, handleScroll}) =>
{
	const {state, dispatch} = useContext(themeContext);
	const [url, setUrl] = useState(Me);
	useEffect(() => {
		const interval = setInterval(() =>
		{
			setUrl(url => url === Me ? Me2 : Me);
		}, 10000)
		return () =>
		{
			clearInterval(interval);
		}
	}, [])
	return (
	<aside className="offcanvas-menu">
			<i className={`fa-solid fa-bars toggle-open ${darkMode ? "text-white" : "text-black"}`} hidden={state.showNavBar} onClick={() => dispatch({type:SHOW_NAVBAR})}></i>

			<nav id={state.showNavBar ? 'show' : undefined} className={darkMode ? "dark" : "light"}>
				<i className={`fa-solid fa-xmark toggle-close ${darkMode ? "text-gray-400" : "text-gray-700"}`} onClick={() => dispatch({type:SHOW_NAVBAR})}></i>
				<div className="about_section">
					<div className='bg-black profile-img'>
						<img className="h-full object-cover w-full" src={url} alt="#"/>
					</div>
					<h3>Markos Bahgat.!</h3>
					<h6>FRONT END DEVELOPER | REACTJS-NEXTJS </h6><br/>
					<SocialMediaLinks darkMode={darkMode} />
				</div>
				<NavLinks handleScroll={handleScroll} darkMode={darkMode} />
				<p id="copyright-text-container">
					© Copyright ©2021 All rights reserved for "Markos Bahgat"| Front End developer located in "Egypt".
				</p>
			</nav>
	</aside>
	);
}
 
export default SideNav;
