// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCgpvMBjbFs4hoJLLlHLwWAC2Up5ZFK13Y',
	authDomain: 'markosbahgat-e49a2.firebaseapp.com',
	projectId: 'markosbahgat-e49a2',
	storageBucket: 'markosbahgat-e49a2.appspot.com',
	messagingSenderId: '545855877633',
	appId: '1:545855877633:web:d92a6ad46326ff5a59c600',
	measurementId: 'G-M4XEG1L79B'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
