import React from 'react';
import './contactForm-style.scss'
import ContactUs from './myform';
import 'styles/responsive.css';

const Form = ({darkMode}) => {
	return (
		<div className="contact_form" id={darkMode ? "dark" : "light"}>
			<span  className={darkMode ? "section_span_dark" : "section_span_light"}>Get In Touch</span>
			<h1 className={darkMode ? "section_h1_dark" : "section_h1_light"}>Contact</h1>
			<div id="form-container">
					<ul id="side-form">
						<li className='flex items-center'>
							<i className="fal fa-globe-stand"></i>
							<a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=markos.website1@gmail.com">
								markos.bahgat@gmail.com
							</a>
						</li>
						<li className='flex items-start'>
							<i className="fal fa-map-marked-alt"></i>
							<h5 className='text-gray-400'>
								Ismaillia City, Egypt.
							</h5>
						</li>
						<li className='flex items-center'>
							<i className="fal fa-phone-alt"></i>
							<a href="tel:+201208444364">
								+201208444364
							</a>
						</li>
					</ul>
				<ContactUs />
			</div>
		</div>
	);
}

export default Form;
