import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import App from './App';
import "@fortawesome/fontawesome-free/css/all.css";
import 'bootstrap/dist/css/bootstrap.min.css';



const root = createRoot(document.getElementById('root'));
root.render(<App />);
