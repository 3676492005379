import React from 'react'
import ProgressBar from './progressBar';
import './skills-style.scss';
import skills from '../../skillls.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { useMediaQuery } from 'react-responsive';

const Skills = ({ darkMode }) =>
{
	const isLG = useMediaQuery({ minWidth:769, maxWidth:1024 })
	const isMD = useMediaQuery({ minWidth: 500, maxWidth: 768 })
	const isMobile = useMediaQuery({ maxWidth: 500, orientation: "portrait" });
	return (
		<div className="skills_container" id={darkMode ? "dark" : "light"}>
			<span className={darkMode ? "section_span_dark" : "section_span_light"}>My Specialty</span>
			<h1 className={darkMode ? "section_h1_dark" : "section_h1_light"}>My Skills</h1>
			<p className="skills_para">
				Hello There, I’m a Full Stack Web Developer Specialized in Front End Development building responsive websites and i'm very good at using HTML5, CSS3, Javascript, TypeScript, ReactJS, Nextjs, Redux, Redux Toolkit, Webpack, JavaScript, Bootstrap, Sass, TailWind-Css, also have medium knowledge of React Native, Vuejs, Python, Cypress and Jest.
			</p>
			<Swiper
				spaceBetween={10}
				centeredSlides={isMobile ? true : false}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				modules={[Autoplay]}
				loop={true}
				slidesPerView={isLG? 3 : (isMD? 2 : (isMobile? 1 : 4))}
					>
				{skills.map((skill, index) => (
					<SwiperSlide key={index}>
						<ProgressBar skill={skill} darkMode={darkMode} />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}
 
export default Skills;
