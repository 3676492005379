import React from 'react';
import './edu-style.scss';
import { motion } from 'framer-motion';

const Education = ({ darkMode }) => {
	const animation = {
		initial: { translateY: 300, opacity: 0 },
		whileInView: { translateY: 0, opacity: 1 },
		viewport: { once: true },
		transition: { duration: 1, type: 'spring' }
	};
	return (
		<div className='edu_container' id={darkMode ? 'dark' : 'light'}>
			<span className={darkMode ? 'section_span_dark' : 'section_span_light'}>Education</span>
			<h1 className={darkMode ? 'section_h1_dark' : 'section_h1_light'}>Education and volunteering Experience</h1>

			<motion.details open {...animation}>
				<summary>
					Bachelor Degree of Mechanical Engineering
					<i className='fa-solid fa-plus'></i>
					<i className='fa-solid fa-minus'></i>
				</summary>
				<p>
					<strong>2018 - 2023 / </strong> Studies Mechanical Engineering at Suez Canal University | Forth Level of Mechanical Departement - (Still in
					University)
				</p>
			</motion.details>

			<motion.details {...animation}>
				<summary>
					Ismaillia secondary School
					<i className='fa-solid fa-plus'></i>
					<i className='fa-solid fa-minus'></i>
				</summary>
				<p>
					<strong>2015 - 2018. / </strong>Studied Mathematical science Major
				</p>
			</motion.details>

			<motion.details {...animation}>
				<summary>
					Technical Support at TedX
					<i className='fa-solid fa-plus'></i>
					<i className='fa-solid fa-minus'></i>
				</summary>
				<p>
					<strong>2019 - 2020. / </strong> Fixing any issues in the website of TedX and help all members with any Tech problems.
				</p>
			</motion.details>

			<motion.details {...animation}>
				<summary>
					Graphic Designer at ASCC
					<i className='fa-solid fa-plus'></i>
					<i className='fa-solid fa-minus'></i>
				</summary>
				<p>
					<strong>2020 - 2021. / </strong>
					<br />
					- Making social media designs using Photoshop and illustrator
					<br />
					- Make some videos using premiere pro and after effect
					<br />
					- Awarded Golden Media Member of Event "Atraf"
					<br />- Awarded Golden Media Member of Event "El Farah".
				</p>
			</motion.details>

			<motion.details {...animation}>
				<summary>
					Delegate OF USA AT Model United Nations SCU
					<i className='fa-solid fa-plus'></i>
					<i className='fa-solid fa-minus'></i>
				</summary>
				<p>
					<strong>2019 - 2020. / </strong> Typically I'm playing the role of a delegate in the United Nations and simulate UN committees.As a delegate
					i have a high level of negotiations skills, soft skills like public speaking , organized , presentation skills , communication skills. my
					role was to defend my country as a USA Delegate against all charges and threats that can compromised the peace and the safety of my people
					and my land.
				</p>
			</motion.details>
		</div>
	);
};

export default Education;
