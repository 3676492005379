import React, { useContext } from 'react';
import themeContext, { SHOW_NAVBAR } from 'context';
import { motion } from 'framer-motion';

const NavLinks = ({ handleScroll }) => {
	const { state, dispatch } = useContext(themeContext);
	const colorChange = (e) => {
		const ul = e.target.parentElement.parentElement.children;
		const lis = [...ul];
		lis.forEach((li) => {
			return li.firstChild.className === 'active' ? (li.firstChild.className = 'notactive') : (li.firstChild.className = 'hvr-underline-from-center');
		});
		e.target.className = 'active';
	};
	const handleClick = (e) => {
		if (state.showNavBar) {
			dispatch({ type: SHOW_NAVBAR });
		}
		e.preventDefault();
		handleScroll(e.target.name);
		colorChange(e);
	};
	return (
		<motion.div
			id='list-items'
			initial={{ translateX: -280 }}
			whileInView={{ translateX: 0 }}
			viewport={{ once: true }}
			transition={{ duration: 1, type: 'spring' }}>
			<hr />
			<ul>
				<li>
					<a name='div.slideShow_main_container' href='#home' className='active' onClick={(e) => handleClick(e)}>
						HOME
					</a>
				</li>
				<li>
					<a name='div.whoami_container' href='#about' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						ABOUT
					</a>
				</li>
				<li>
					<a name='div.services_container' href='#services' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						SERVICES
					</a>
				</li>
				<li>
					<a name='div.skills_container' href='#skills' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						SKILLS
					</a>
				</li>
				<li>
					<a name='div.edu_container' href='#education' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						EDUCATION
					</a>
				</li>
				<li>
					<a name='div.main_exp_container' href='#experience' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						EXPERIENCES
					</a>
				</li>
				<li>
					<a name='div.main_work_container' href='#latest_work' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						WORK
					</a>
				</li>
				<li>
					<a name='div.contact_form' href='#contact_form' className=' hvr-underline-from-center' onClick={(e) => handleClick(e)}>
						CONTACT
					</a>
				</li>
			</ul>
			<hr />
		</motion.div>
	);
};

export default NavLinks;
