import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/responsive.css';
import { useForm } from 'react-hook-form';

const ContactUs = () =>
{
  const notify = () => toast.success("Message sent successfully...!");
  const form = useRef();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const sendEmail = (data) => {

    emailjs.sendForm('markos', 'markos', data, 'user_xWxaqPvE4K7xNeqiRB7LO')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  const onSubmit = data =>
  {

    console.log(data);
    sendEmail(data);
    notify();
  };
  return (
    <div id="form">
        <form  ref={form} onSubmit={handleSubmit(onSubmit)}>
            <input
              name="name"
              placeholder="Full Name"
              type="text"
          {...register("name", { required: true })} />
        
            <input
              name="email"
              placeholder="Email"
              type="email"
              {...register("email", { required: true })} />
            <input
              name="text"
              placeholder="subject"
              type="text"
              {...register("subject", { required: true })} />
            <input
              name="phone-number"
              placeholder="Phone  Number"
              type="tel"
              {...register("phoneNumber", { required: true , pattern:/[\d]{11}/})} />
            <textarea
              type="text"
              name="message"
              placeholder="Message"
              spellCheck="true"
              maxLength={500}
              {...register("message", { required: true })}></textarea>
            <button
              type="submit"
              name="button"
              id="submit-button"
              >Send Massage</button>
        </form>
        <ToastContainer />
    </div>
  );
}
 
export default ContactUs;
