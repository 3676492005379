import React, { useEffect, useState } from 'react';

const ScrollButton = ({ darkMode }) =>
{
    const [show, setShow] = useState(false);
    const handleGoUp = () =>
    {
        window.scrollTo({
            top: 0,
            behavior:"smooth"
        })
    }
    useEffect(() =>
    {
        window.addEventListener('scroll', () =>
    {
        if (window.scrollY > 800) setShow(true)
        else setShow(false);
        })
        return () =>
        {
            window.removeEventListener("scroll")
        }
    }, [])
    return ( 
        <div
            onClick={handleGoUp}
            className="w-[40px] h-[40px] flex items-center justify-center rounded cursor-pointer fixed bottom-5 right-5 z-50"
            style={{ backgroundColor: `${darkMode ? "white" : "black"}` , display:`${show ? "flex" : "none"}`}}>
            <i
                className="fa-solid fa-arrow-up-long "
                style={{ color: `${darkMode ? "black" : "white"}` }}
            ></i>
        </div>
     );
}
 
export default ScrollButton;
