import React, { useEffect, useState } from 'react';
import './work-style.scss';
import ImgHover from './imgHover';
import { motion } from 'framer-motion';
import GetAllProjects from 'api/getAllPosts';

const Work = ({ darkMode }) => {
	const [limit, setLimit] = useState(6);
	const [projects, setAllProjects] = useState([]);
	useEffect(() => {
		GetAllProjects(limit)
			.then((res) => {
				setAllProjects(res);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [limit]);

	return (
		<div className='main_work_container' id={darkMode ? 'dark' : 'light'}>
			<span className={darkMode ? 'section_span_dark' : 'section_span_light'}>My Work</span>
			<h1 className={darkMode ? 'section_h1_dark' : 'section_h1_light'}>Recent Work</h1>
			<div id='work-container'>
				{projects.map((item, index) => (
					<ImgHover key={index} item={item} />
				))}
			</div>
			<motion.button
				className='loadMoreWork_button'
				onClick={() => setLimit((increment) => increment + 8)}
				initial={{ width: 0, opacity: 0 }}
				whileInView={{ width: '100%', opacity: 1 }}
				viewport={{ once: true }}
				transition={{ duration: 1, type: 'spring' }}>
				Load More <i className='fas fa-redo-alt'></i>
			</motion.button>
		</div>
	);
};

export default Work;
