import * as React from 'react';
import { twitter, linkedin, gmail, github, whatsapp } from 'assets/icons';
import './socialMedia-style.scss';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const SocialMediaLinks = ({darkMode}) =>
{
   
    return (
        <ul className='media_list'>
            <Tooltip title="LinkedIn" placement="top" arrow TransitionComponent={Zoom}>
                <li>
                    <a href="https://www.linkedin.com/in/markosbahgat" target="_blank" rel="noreferrer">
                        <img src={linkedin} alt="Markos Bahgat linkedin profile and page" className='socialMedia_icon'/>
                    </a>
                </li>
            </Tooltip>
            <Tooltip title="Github Primary Account" placement="top" arrow TransitionComponent={Zoom}>
                <li>
                    <a href="https://github.com/markosbahgat"  target="_blank" rel="noreferrer">
                        <img
                            src={github}
                            alt="Markos Bahgat github profile and page"
                            className='socialMedia_icon'
                            style={{filter:`${darkMode ? "invert()" : "none"}`}}/>
                    </a>
                </li>
            </Tooltip>
            <Tooltip title="Twitter" placement="top" arrow TransitionComponent={Zoom}>
                <li>
                    <a href="https://twitter.com/bahgat_markos"  target="_blank" rel="noreferrer">
                        <img
                            src={twitter}
                            alt="Markos Bahgat twitter profile and page"
                            className='socialMedia_icon'
                            style={{ transform: `${darkMode ? "scale(1.2)" : "none"}` }} />
                    </a>
                </li>
            </Tooltip>
            <Tooltip title="WhatsApp" placement="top" arrow TransitionComponent={Zoom}>
                <li>
                    <a href="https://wa.me/+201208444364" target="_blank" rel="noreferrer">
                        <img src={whatsapp} alt="Markos Bahgat whatsapp Number" className='socialMedia_icon'/>
                    </a>
                </li>
            </Tooltip>
            <Tooltip title="Github Secondary Account" placement="top" arrow TransitionComponent={Zoom}>
                <li>
                    <a href="https://github.com/markosbahgat111"  target="_blank" rel="noreferrer">
                        <img
                            src={github}
                            alt="Markos Bahgat github profile and page"
                            className='socialMedia_icon'
                            style={{ filter: `${darkMode ? "invert()" : "none"}` }} />
                    </a>
                </li>
            </Tooltip>
            <Tooltip title="Gmail" placement="top" arrow TransitionComponent={Zoom}>
                <li>
                    <a href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=markos.website1@gmail.com" target="_blank" rel="noreferrer">
                        <img src={gmail} alt="Markos Bahgat gmail email" className='socialMedia_icon'/>
                    </a>
                </li>
            </Tooltip>
        </ul>
     );
}
 
export default SocialMediaLinks;
