import './progressBar-style.scss';
import React from 'react';


const ProgressBar = ({skill, darkMode}) =>
{
    const smallIcon = ["HTML", "CSS", "TypeScript", "ReactJs", "React Native", "Nextjs"];
    const bigIcon = ["JavaScript", "Sass", "Git"];
    return (
        <div className="skill_box" style={{borderTop:`1.5px solid ${skill.color}`}} id={darkMode ? "dark" : "light"}>
            <div className="percent">
                <svg>
                    <circle cx={50} cy={50} r={50}></circle>
                    <circle cx={50} cy={50} r={50} className="target" style={{strokeDashoffset:`calc(315 - (315 * ${skill.percent}) / 100)`, stroke:skill.color}}></circle>
                </svg>
                <div className="number">
                    <h2>{skill.percent} <span>%</span></h2>
                </div>
            </div>
            <div className='flex flex-row items-center justify-center gap-1'>
                <h2 className="text"> {skill.name} </h2>
                <img src={skill.iconImg} alt={skill.name} style={{
                    width: `${smallIcon.includes(skill.name) ? "10%" : (bigIcon.includes(skill.name) ? "20%" : "15%")}`
                }} />
            </div>
        </div>
     );
}
 
export default ProgressBar;
