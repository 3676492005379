import React from 'react';
import './style.scss';
import { motion } from 'framer-motion';

const WhatIDo = ({ darkMode }) =>
{
    // to make animation to the clipPath in poly1 you need to watch this video https://www.youtube.com/watch?v=hkhskSrT5SY;
    const animateSVGObject = {
        animate: { clipPath: "path('M75,37.2 128.8,68.4 128.8,131.2 75,162.4 21,130.8 21,68.4')" },
        viewport:{ once: true },
        transition:{ duration: 1 }
    }
    const animateContainerIn = {
        initial: { translateX: -150, opacity: 0 },
        whileInView: { translateX: 0, opacity: 1 },
        viewport: { once: true },
        transition:{duration:1, type:"spring"}
    }
    const animateContainerOut = {
        initial: { translateX: 150, opacity: 0 },
        whileInView: { translateX: 0, opacity: 1 },
        viewport: { once: true },
        transition:{duration:1, type:"spring"}
    }
    return (
        <div className="services_container" id={darkMode ? "dark" : "light"}>
            <span className={darkMode ? "section_span_dark" : "section_span_light"}>
                What I do?
            </span>
            <h1 className={darkMode ? "section_h1_dark" : "section_h1_light"}>
                Here are some of my expertise
            </h1>
            <div id="poly-container">
                <motion.div {...animateContainerIn}>
                    <motion.div
                    className="poly1"
                    {...animateSVGObject}>
                        <i className="fal fa-lightbulb-on"></i>
                    </motion.div>
                    <section className="icon-sec s1">
                        <h5>Innovative Ideas</h5>
                        <p>That Will Make Your Website A Whole Lot Easier Than You Think.</p>
                    </section>
                </motion.div>					
                <motion.div>
                    <motion.div className="poly2" {...animateSVGObject}>
                        <i className="fal fa-database"></i>
                    </motion.div>
                    <section className="icon-sec s2">
                        <h5>Software</h5>
                        <p>That Has Quality Models with maintainability, flexibility and testability capabilities.</p>
                    </section>
                </motion.div>
                <motion.div {...animateContainerOut}>
                    <motion.div className="poly3" {...animateSVGObject}>
                        <i className="fal fa-globe-stand"></i>
                    </motion.div>
                    <section className="icon-sec s3">
                        <h5>Responsive Website</h5>
                        <p>Design is about creating web pages that look good on all devices, no matter what.</p>
                    </section>
                </motion.div>
                <motion.div {...animateContainerIn}>
                    <motion.div className="poly4" {...animateSVGObject}>
                        <i className="fal fa-layer-group"></i>
                    </motion.div>
                    <section className="icon-sec s4">
                        <h5>UI/UX Design</h5>
                        <p>That focusses on best user experiences providing mutliple device responsive solutions.</p>
                    </section>
                </motion.div>
                <motion.div >
                    <motion.div className="poly5" {...animateSVGObject}>
                        <i className="fal fa-tachometer-fast"></i>
                    </motion.div>
                    <section className="icon-sec s5">
                        <h5>High Performance</h5>
                        <p>Websites built to be  better, faster, and  more efficient than others websites.</p>
                    </section>
                </motion.div>
                <motion.div {...animateContainerOut}>
                    <motion.div className="poly6" {...animateSVGObject}>
                        <i className="fad fa-user-headset"></i>
                    </motion.div>
                    <section className="icon-sec s6">
                        <h5>Support 24/7</h5>
                        <p>provide high-quality Technical Support with personal assistance for our customers</p>
                    </section>
                </motion.div>
            </div>
        </div>
    );
}
 
export default WhatIDo;
