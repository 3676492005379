import { createContext } from "react";

const Actions = {
	DARK_MODE: "DARK_MODE",
	SHOW_MODEL: "SHOW_MODEL",
	SHOW_NAVBAR:"SHOW_NAVBAR"
};
const themeContext = createContext();

export function reducer(state, action) {
	switch (action.type) {
		case Actions.DARK_MODE:
			return { ...state, darkMode: !state.darkMode };
		case Actions.SHOW_MODEL:
			return { ...state, showModel: !state.showModel };
		case Actions.SHOW_NAVBAR:
			return { ...state, showNavBar: !state.showNavBar };
		default:
			return initialState;
	}
}

export const initialState = {
	darkMode: true,
	showModel: false,
	showNavBar:false
};

export default themeContext;
export const { DARK_MODE, SHOW_MODEL, SHOW_NAVBAR } = Actions;
