import React, {useContext} from 'react';
import './whoami.scss';
import themeContext, {SHOW_MODEL} from 'context';
import { motion } from 'framer-motion';

const WhoAMI = ({ darkMode }) =>
{
	const themeState = useContext(themeContext);
	return (
		<div className="whoami_container" id={darkMode ? "dark" : "light"}>
			<motion.div
				id="about-section-container"
				initial={{ opacity: 0, translateY: 100 }}
				whileInView={{ opacity: 1, translateY: 0 }}
				viewport={{ once: true }}
				transition={{ duration: 1 }}>
				<span className={darkMode ? "section_span_dark" : "section_span_light"}>
					About Me
				</span>
				<h1 className={darkMode ? "section_h1_dark" : "section_h1_light"}>
					WHO AM I?
				</h1>
				<p>
					<strong>Hi I'm Markos Bahgat</strong>.
					I'm an experienced Front End Developer who constantly seeks out innovative solutions to everyday problems. In my three years in this industry, I've honed my analytical thinking and collaboration skills, and I love working with a team.
				</p>
				
				<p className='mt-5'>
					“Before my current position, I was a junior Front End Developer with web First. While working there, I committed to web development, which has been my specialty for two years".
				</p>
			</motion.div>


			<div
				className="sectionThree_icons"
				>
				<motion.div
					className='dev_icon'
					initial={{ translateX: -100 }}
					whileInView={{  translateX: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 1 }}>
					<i className="fal fa-lightbulb-on text-[#2c98f0]"></i>
					<span>Graphic Design</span>
					<hr className='text-[#2c98f0]'/>
				</motion.div>
				<motion.div
					className='dev_icon'
					initial={{ translateY: 100 }}
					whileInView={{ translateY: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 1 }}>
				<i className="fal fa-globe-stand text-[#ec5453]"></i>
					<span>Web Design</span>
					<hr className="text-[#ec5453]"/>
				</motion.div>
				<motion.div
					className='dev_icon'
					initial={{ translateY: 100 }}
					whileInView={{ translateY: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 1 }}>
					<i className="fal fa-database text-[#f9bf3f]"></i>
					<span>Software</span>
					<hr className='text-[#f9bf3f]'/>
				</motion.div>
				<motion.div
					className='dev_icon'
					initial={{ translateX: 100 }}
					whileInView={{  translateX: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 1 }}>
					<i className="fal fa-mobile text-[#a84cb8]"></i>
					<span>Application</span>
					<hr className="text-[#a84cb8]"/>
				</motion.div>
			</div>


			<motion.div
				className='sectionFour'
				initial={{ opacity: 0, translateY: 100 }}
				whileInView={{ opacity: 1, translateY: 0 }}
				viewport={{ once: true }}
				transition={{ duration: 1 }}>
				<p>
				I am happy to know you<br/>
				that 30+ projects done successfully!
				</p>
				<button type="button" className="HireMe_button" onClick={() => themeState.dispatch({type:SHOW_MODEL})}>Hire Me!</button>
			</motion.div>
		</div>
	);
}
 
export default WhoAMI;
