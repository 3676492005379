import React, { useReducer } from 'react';
import './App.scss';
import 'styles/global.scss';
import SideContainer from './Components/sideNav/sidecontainer';
import Slides from './Components/slideShow/slideshow';
import WhoAMI from './Components/whoAmI-Section/whoami';
import WhatIDo from './Components/whatIDo-Section/whatido';
import Skills from './Components/Skills/skillscontainer';
import Education from './Components/education/educonatiner';
import Experience from './Components/experience/experience';
import Work from './Components/work/workcontainer';
import Form from './Components/contactForm/contactform';
import SideNav from 'Components/sideResponsiveNav/sidenaveresponsive';
import themeContext, { initialState, reducer, SHOW_NAVBAR } from './context';
import ScrollButton from 'Components/shared/scrollToTopButton';
import Popup from 'Components/freelancingModal/popupwindow';

function App() {
	const [state, dispatch] = useReducer(reducer, initialState);
	const handleScroll = (element) => {
		if (typeof element === 'string') {
			const target = document.querySelector(element);
			target.scrollIntoView({ behavior: 'smooth' });
		}
	};
	return (
		<themeContext.Provider value={{ state, dispatch }}>
			<div className='app_container' id={state.darkMode ? 'dark' : 'light'}>
				<SideNav darkMode={state.darkMode} handleScroll={handleScroll} />
				<SideContainer darkMode={state.darkMode} handleScroll={handleScroll} />
				<Popup />
				<ScrollButton darkMode={state.darkMode} />
				<div
					className='content_container'
					id={state.darkMode ? 'dark' : 'light'}
					onClick={() => (state.showNavBar ? dispatch({ type: SHOW_NAVBAR }) : console.log('Hello World!'))}>
					<Slides darkMode={state.darkMode} />
					<div className='indented_container'>
						<WhoAMI darkMode={state.darkMode} />
						<WhatIDo darkMode={state.darkMode} />
						<Skills darkMode={state.darkMode} />
						<Work darkMode={state.darkMode} />
						<Experience darkMode={state.darkMode} />
						<Education darkMode={state.darkMode} />
						<Form darkMode={state.darkMode} />
					</div>
				</div>
			</div>
		</themeContext.Provider>
	);
}

export default App;
