import React, {useEffect, useState} from 'react';
import './sideNav-style.scss';
import Me from 'assets/Images/n.png';
import Me2 from 'assets/Images/m.png';
import SocialMediaLinks from '../shared/socialMedia-links';
import NavLinks from 'Components/shared/navLinks';
import { motion } from 'framer-motion';

const SideContainer = ({darkMode, handleScroll}) =>
{
	const [url, setUrl] = useState(Me);

	useEffect(() => {
		const interval = setInterval(() =>
		{
			setUrl(url => url === Me ? Me2 : Me);
		}, 10000)
		return () =>
		{
			clearInterval(interval);
		}
	}, [])

	return (
		<aside
			className="aside_container"
			id={darkMode ? "dark" : "light"}
		>
			<motion.div
				id="about-intro"
				initial={{ translateX: -350}}
				whileInView={{ translateX: 0}}
				viewport={{ once: true }}
				transition={{ duration: 1, type: "spring" }}>
				<div className='bg-black profile-img'>
					<img className="h-full object-cover w-full" src={url} alt="#"/>
				</div>
				<h3>Markos Bahgat.!</h3>
				<h6>FRONT END DEVELOPER | REACTJS-NEXTJS </h6>
				<SocialMediaLinks darkMode={darkMode} />
			</motion.div>
			<NavLinks handleScroll={handleScroll}/>
			<motion.div
				id="copyright-text-container"
			initial={{ translateX: -350}}
			whileInView={{ translateX: 0}}
			viewport={{ once: true }}
			transition={{ duration: 1, type: "spring" }}>
				<p>© Copyright ©2022 All rights reserved for "Markos Bahgat"| Front End web developer located in "Egypt".</p>
			</motion.div>	
		</aside>
	);
}
 
export default SideContainer;
