import { query, collection, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from 'firebase.init';

export default async function GetAllProjects(max) {
	try {
		const q = query(collection(db, 'projects'), orderBy('createdAt', 'asc'), limit(max));
		const projects = await getDocs(q).then((querySnapshot) => {
			const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			return newData;
		});
		return projects;
	} catch (error) {
		console.log(error);
		alert(error.message);
	}
}
