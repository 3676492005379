import React, { useContext } from 'react';
import './modal-style.scss'
import fiverr from 'assets/Images/Fiverr New.png'
import upwork from 'assets/Images/Upwork-Logo.png'
import freelancer from 'assets/Images/Freelancer_logo.png'
import mostaql from 'assets/Images/88481-1456078475-logo-cs.png'
import 'styles/responsive.css';
import themeContext, { SHOW_MODEL } from 'context';

const Popup = () =>
{
  const themeState = useContext(themeContext);
  return (
        <div className={`modal__container  ${themeState.state.showModel ? "show-modal" : undefined}`}>
            <div className="modal__content">
                <div className="modal__close close-modal" title="Close" onClick={() => themeState.dispatch({type:SHOW_MODEL})}>
                    <i className='fas fa-times'></i>
                </div>
                <h5 >Hiring Platforms</h5>
                <div className="logos_container">
                    <a href="https://www.fiverr.com/markosbahgat" target="_blank" rel="noreferrer">
                      <img src={fiverr} alt="fiverr" className="w-full h-[100px] object-contain"/>
                    </a>
                    <a href="https://www.upwork.com/freelancers/~01672fd6bb0789ff71" target="_blank" rel="noreferrer">
                      <img src={upwork} alt="upwork" className="w-full h-[70px] object-contain"/>
                    </a>
                    <a href="https://www.freelancer.com/u/markosbahgat" target="_blank" rel="noreferrer">
                      <img src={freelancer} alt="freelancer" className="w-full h-[40px] object-contain my-8"/>
                    </a>
                    <a href="https://mostaql.com/u/MarkosFreelance" target="_blank" rel="noreferrer">
                      <img src={mostaql} alt="mostaql" className="w-full h-[60px] object-contain"/>
                    </a>
                </div>
            </div>
        </div>
  );
}
 
export default Popup;
