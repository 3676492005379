import React from 'react';
import './experience-style.scss';
import { motion } from 'framer-motion';

const Experience = ({ darkMode }) => {
	const animation = {
		initial: { translateX: -300, opacity: 0 },
		whileInView: { translateX: 0, opacity: 1 },
		viewport: { once: true },
		transition: { duration: 1, type: 'spring' }
	};
	return (
		<div className='main_exp_container' id={darkMode ? 'dark' : 'light'}>
			<span className={darkMode ? 'section_span_dark' : 'section_span_light'}>Experience</span>
			<h1 className={darkMode ? 'section_h1_dark' : 'section_h1_light'}>Work Experience</h1>

			<motion.div id='exp-container' {...animation}>
				<i className='fal fa-pencil-alt' style={{ borderColor: '#2c98f0', borderRadius: '50%', backgroundColor: '#2c98f0' }}></i>
				<span>
					<strong>Full Stack Developer</strong> <small style={{ fontWeight: '300' }}>2020-2021</small>
				</span>
				<p>
					Spend almost two years in the freelancing field as a full-stack web developer in the period of the coronavirus,working from home, but this
					the time I developed my skills by learning a python programming language and Django back-end web framework, to be a full stack developer,
					creating websites from scratch.
				</p>
			</motion.div>
			<motion.div id='exp-container' {...animation}>
				<i className='fal fa-pencil-alt' style={{ borderColor: '#ec5453', borderRadius: '50%', backgroundColor: '#ec5453' }}></i>
				<span>
					<strong>Front End Developer at ElPatal Group</strong> <small style={{ fontWeight: '300' }}>2019-2020</small>
				</span>
				<p>
					I used my skills in programming such as Html5, CSS 3, JavaScript, React, bootstrap, sass, To create fully dynamic Responsive websites,
					landing pages, dashboards, And to edit some websites by adding new features to them.
				</p>
			</motion.div>
			<motion.div id='exp-container' {...animation}>
				<i className='fal fa-pencil-alt' style={{ borderColor: '#a84cb8', borderRadius: '50%', backgroundColor: '#a84cb8' }}></i>
				<span>
					<strong>Graphic Designer</strong> <small style={{ fontWeight: '300' }}>2018-2020</small>
				</span>
				<p>
					My Experience in Photoshop and illustrator helped me to jump into the graphic deign field by creating social media designs and simple
					landing pages to provide my services on mostaql to better dealing with clients.
				</p>
			</motion.div>
		</div>
	);
};

export default Experience;
